@import url('https://fonts.googleapis.com/css2?family=Dancing+Script&display=swap');

.App {
  text-align: center;
}

.App-header {
  font-family: 'Dancing Script', cursive;
  background-color: #282c34;
  padding: 20px;
  font-size: 36px;
  color: white;
}

.App-video {
  padding: 20px;
}

.App-story {
  padding: 20px;
  text-align: left;
  font-family: 'Dancing Script', cursive;
}

@keyframes happy-story {
  0% { background-color: #ffff99; }
  50% { background-color: #ccff99; }
  100% { background-color: #ffff99; }
}

@keyframes sad-story {
  0% { background-color: #cccccc; }
  50% { background-color: #999999; }
  100% { background-color: #cccccc; }
}

.App-story-happy {
  padding: 20px;
  text-align: center;
  font-family: 'Dancing Script', cursive;
  font-size: 24px;
  color: rgb(73, 63, 116);
  animation: happy-story 10s infinite;
}

.App-story-sad {
  padding: 20px;
  text-align: center;
  font-family: 'Dancing Script', cursive;
  font-size: 24px;
  color: rgb(161, 77, 77);
  animation: sad-story 10s infinite;
}

/* Media query for mobile devices */
@media (max-width: 768px) {
  .App-header {
    font-size: 36px;
  }

  .App-video {
    width: 100%;
  }

  .App-story {
    font-size: 48px;
  }
}